import { styled } from 'styled-components';

export const ChatListItemsStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 10px;
  max-height: 65vh;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a98d8d2d;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(169, 141, 141, 0.22);
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
`;

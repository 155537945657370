import {
  FINMS_URL,
  FINMS_URL_API,
} from '../../packages/keycloak-client/constants';
import { useLayoutEffect, useState } from 'react';
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ROUTER_PATH } from '../../router/path';
import { useTranslation } from 'react-i18next';
import { axios } from '../../shared/exios';
import { useCookiesCustom } from '../../hooks/useCookieCustom';

const ConfirmInvite = () => {
  const { cookie, removeCookie, setCookie } = useCookiesCustom();
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const urlParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<string>('');

  const redirectToFinmsapp = () => {
    setCookie('inviteNotificationShown', 'true');
    window.location.href = `${FINMS_URL}`;
  };

  const redirectToLoginPage = () => {
    navigate(
      `${ROUTER_PATH.FormsPages.LOGIN}?redirect_url=${FINMS_URL}/redirect-page`
    );
  };

  const redirectToMyDeals = () => {
    navigate(ROUTER_PATH.AuthRoutes.MY_DEALS);
  };

  const showInviteSuccessNotification = () => {
    notification.success({
      style: { background: '#2B2B36' },
      message: (
        <span
          style={{
            fontSize: '16px',
            fontWeight: '700',
            color: '#FBFBFF',
          }}
        >
          {t('titles.success')}
        </span>
      ),
      duration: 5,
      description: (
        <span style={{ color: '#FBFBFF' }}>
          {t('descriptions.successfullyRegisteredInvite')}
        </span>
      ),
    });
  };

  const showNotificationAccessRefused = () => {
    notification.error({
      style: { background: '#2B2B36' },
      duration: 5,
      message: (
        <span
          style={{
            fontSize: '16px',
            fontWeight: '700',
            color: '#FBFBFF',
          }}
        >
          {t('titles.usingLinkAnotherEmail')}
        </span>
      ),
      description: (
        <span style={{ color: '#FBFBFF' }}>
          {t('descriptions.redirectLoginPage')}
        </span>
      ),
    });
  };

  const showNotificationAlreadyHaveCompany = () => {
    notification.info({
      style: { background: '#2B2B36' },
      duration: 5,
      message: (
        <span
          style={{
            fontSize: '16px',
            fontWeight: '700',
            color: '#FBFBFF',
          }}
        >
          {t('titles.youAlreadyHaveCompany')}
        </span>
      ),
      description: (
        <span style={{ color: '#FBFBFF' }}>
          {t('descriptions.redirectFinMS')}
        </span>
      ),
    });
  };

  const showNotificationNotRegister = () => {
    notification.error({
      style: { background: '#2B2B36' },
      duration: 5,
      message: (
        <span
          style={{
            fontSize: '16px',
            fontWeight: '700',
            color: '#FBFBFF',
          }}
        >
          {t('titles.youNotRegistred')}
        </span>
      ),
      description: (
        <span style={{ color: '#FBFBFF' }}>
          {t('descriptions.redirectLoginPage')}
        </span>
      ),
    });
  };

  const confirmInvite = async ({ inviteToken }: { inviteToken: string }) => {
    try {
      const res = await axios.post(
        `${FINMS_URL_API}/server/api/user-accounts`,
        {},
        {
          headers: {
            'Invite-Token': inviteToken,
          },
        }
      );
      return res?.data?.data?.company_id;
    } catch (e: any) {
      console.error('ResInviteError', e);
      setErrorMessage(e?.response?.data?.message || 'Something went wrong');
      return e?.response?.data?.message;
    }
  };

  const removeInviteTokenFromCookie = () => {
    removeCookie('inviteToken');
  };

  const clearRedirectUrl = () => {
    localStorage.removeItem('redirectUrl');
  };

  const clearInviteToken = () => {
    localStorage.removeItem('inviteToken');
  };

  const clearEmailVerified = () => {
    localStorage.removeItem('email_verified');
  };

  const getQueryInvite = async () => {
    const inviteToken =
      urlParams.get('inviteToken') ||
      cookie.inviteToken ||
      localStorage.getItem('inviteToken') ||
      '';
    if (inviteToken && !isConfirmed) {
      if (cookie.token) {
        await confirmInvite({ inviteToken })
          .then((id) => {
            if (id) {
              setIsConfirmed(true);
              showInviteSuccessNotification();
              clearRedirectUrl();
              clearInviteToken();
              clearEmailVerified();
              redirectToFinmsapp();
            }
          })
          .catch(() => {
            if (errorMessage.includes('Access refused')) {
              showNotificationAccessRefused();
              redirectToLoginPage();
            }
            if (errorMessage.includes('No query results for model')) {
              clearInviteToken();
              clearRedirectUrl();
              clearEmailVerified();
              removeInviteTokenFromCookie();
              showNotificationAlreadyHaveCompany();
              localStorage.setItem('inviteNotificationShown', 'true');
              redirectToFinmsapp();
            } else if (!cookie.token) {
              showNotificationNotRegister();
              redirectToLoginPage();
            }
          });
      } else if (!cookie.token) {
        showNotificationNotRegister();
        redirectToLoginPage();
      }
    } else {
      if (!inviteToken && !!cookie.token) {
        clearRedirectUrl();
        clearInviteToken();
        redirectToMyDeals();
      } else if (!cookie.token) {
        clearInviteToken();
        clearRedirectUrl();
        setTimeout(() => {
          navigate(ROUTER_PATH.FormsPages.LOGIN);
        }, 4000);
      }
    }
  };

  useLayoutEffect(() => {
    getQueryInvite().then();
  }, []);

  return <></>;
};

export default ConfirmInvite;

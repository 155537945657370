import React, { useEffect } from 'react';
import './ChatAsideBell.css';
import { CommentOutlined } from '@ant-design/icons';
import { BadgeStyled, DropdownStyled } from './ChatAsideBell.style';
import { ChatAside } from '../ChatAside/ChatAside';
import { useChatsState } from '../../../../state/chats/useChatsState';

export const ChatAsideBell = () => {
  const clientCount = useChatsState(
    (state) => state.unreadMessagesCounts.clientCount
  );

  const corporateCount = useChatsState(
    (state) => state.unreadMessagesCounts.corporateCount
  );

  const setUnreadMessagesCount = useChatsState(
    (state) => state.setUnreadMessagesCount
  );

  useEffect(() => {
    setUnreadMessagesCount();
  }, []);

  return (
    <DropdownStyled
      trigger={['click']}
      className={'ant-dropdown-notifications-custom'}
      dropdownRender={() => <ChatAside />}
      destroyPopupOnHide
    >
      <BadgeStyled
        count={clientCount + corporateCount}
        size={'default'}
        color={'linear-gradient(45deg, #088473 0%, #12BFA8 100%)'}
        showZero={true}
        offset={[-1, 1]}
      >
        <CommentOutlined />
      </BadgeStyled>
    </DropdownStyled>
  );
};

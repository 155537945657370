import styled, { css } from 'styled-components';
import { Layout } from 'antd';

export const LayoutStyled = styled(Layout)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #14141f;
  width: 100%;
`;

export const LayoutInnerStyled = styled.div<{
  $isScreen767?: boolean;
}>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-content: center;
  margin-top: -130px;

  ${({ $isScreen767 }) =>
    !$isScreen767 &&
    css`
      grid-template-columns: 1fr;
      width: 100%;
    `}
`;

export const LayoutInnerLeftStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: #14141f;
  margin-left: 30px;
`;

export const LayoutInnerRightStyled = styled(Layout)<{
  $isScreen767?: boolean;
}>`
  background: #14141f;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ $isScreen767 }) =>
    !$isScreen767 &&
    css`
      margin-right: 0;
    `}
`;

export const WrapperLanguageStyled = styled.div`
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LanguageStyled = styled.span`
  margin-left: 10px;
  cursor: pointer;
  text-transform: uppercase;
`;

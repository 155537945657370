import { getCookieByName } from '../../helpers/controlCookies';
import axios from 'axios';
import { ACCOUNT_URL } from '../../../packages/keycloak-client/constants';

export const getAccessTokenToConnectWS = async () => {
  if (!getCookieByName('refresh_token')) return;
  const res = await axios.post(
    `${ACCOUNT_URL}/server/edenex-account/api/refresh-token`,
    {
      refresh_token: getCookieByName('refresh_token')!.replace('Bearer ', ''),
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: '',
      },
    }
  );

  return res.data.data.access_token;
};

export const getAccessTokenToConnectWSReferee = async () => {
  const res = await axios.post(
    `${ACCOUNT_URL}/server/edenex-account/api/refresh-token`,
    {
      refresh_token: localStorage
        .getItem('referee_refresh_token')!
        .replace('Bearer ', ''),
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: '',
      },
    }
  );
  localStorage.setItem('referee_refresh_token', res.data.data.refresh_token);
  localStorage.setItem('referee_token', res.data.data.access_token);
  return res.data.data.access_token;
};

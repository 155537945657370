import { CHAT_URL } from 'packages/keycloak-client/constants';
import { axios } from 'packages/keycloak-client/exios';

import { getCookieByName } from 'shared/helpers/controlCookies';

interface ICountChats {
  user: {
    unread_message_count: number;
  };
  exchange_point: {
    exchange_points: [
      {
        exchange_point_id: string;
        unread_message_count: number;
      }
    ];
  };
  referee: {
    unread_message_count: number;
  };
}

export const getCountChats = async () => {
  try {
    const { data } = await axios.get<ICountChats>(
      `${CHAT_URL}/server/chat/api/message/get-count`,
      {
        headers: {
          Authorization: ``,
        },
      }
    );

    return data;
  } catch (e) {
    console.log(e);
  }
};

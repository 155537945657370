import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import {
  ChatListLeftStyled,
  ChatListRightStyled,
  ChatListStyled,
} from './assets/styles';
import ChatListHeader from './components/ChatListHeader/ChatListHeader';
import ChatListItems from './components/ChatListItems/ChatListItems';
import { TTabs, useChatListState } from './state/useChatListState';
import ChatListFilters from './components/ChatListFilters/ChatListFilters';
import { ListItemSkeleton } from 'shared/ui/list-item-skeleton';
import { OfferChatWrapper } from '../../features/Chats/ui/OfferChatWrapper/OfferChatWrapper';
import { useChatsState } from '../../state/chats/useChatsState';

const ChatList = () => {
  const openedEntity = useChatsState((state) => state.openedEntity);
  const typeTabs = useChatListState((state) => state.typeTabs);
  const setTypeTabs = useChatListState((state) => state.setTypeTabs);
  const openChatId = useChatListState((state) => state.openChatId);
  const isOpenChat = useChatListState((state) => state.isOpenChat);
  const setIsOpenChat = useChatListState((state) => state.setIsOpenChat);
  const resetFields = useChatListState((state) => state.resetFields);
  const isLoadingSkeleton = useChatListState(
    (state) => state.isLoadingSkeleton
  );
  const navigate = useNavigate();

  const partnersChatsList = useChatListState(
    (state) => state.partnersChatsList
  );
  const phexChatsList = useChatListState((state) => state.phexChatsList);

  const setIsLoadingSkeleton = useChatListState(
    (state) => state.setIsLoadingSkeleton
  );

  const handleTatyTabs = (value: TTabs) => {
    setIsLoadingSkeleton(true);
    setTypeTabs(value);
  };

  useEffect(() => {
    return () => resetFields();
  }, [resetFields]);

  useEffect(() => {
    if (openedEntity.id && openedEntity.entityType === 'offer' && openChatId) {
      setIsOpenChat(true);
    }
  }, [openedEntity, openedEntity.id, isOpenChat, openChatId]);

  return (
    <ChatListStyled $isOpen={isOpenChat}>
      <ChatListLeftStyled $isOpen={isOpenChat}>
        <ChatListHeader onCrossClick={() => navigate(-1)} />
        <ChatListFilters typeTabs={typeTabs} setTypeTabs={handleTatyTabs} />
        {isLoadingSkeleton ? (
          <WrapSkeletonContainerStyled>
            {Array.from({ length: 6 }).map((el, i) => (
              <ListItemSkeletonStyled key={i} />
            ))}
          </WrapSkeletonContainerStyled>
        ) : (
          <ChatListItems
            typeTabs={typeTabs}
            items={typeTabs === 'personal' ? partnersChatsList : phexChatsList}
          />
        )}
      </ChatListLeftStyled>
      {openedEntity?.entityType === 'offer' && (
        <ChatListRightStyled $isOpen={openedEntity.entityType === 'offer'}>
          <OfferChatWrapper />
        </ChatListRightStyled>
      )}
    </ChatListStyled>
  );
};

export default ChatList;

export const ListItemSkeletonStyled = styled(ListItemSkeleton)`
  height: 80px;
`;

export const WrapSkeletonContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

import { Button, Form, Input, notification } from 'antd';
import { useLoginState } from '../../state/useLoginState';
import { useNavigate } from 'react-router-dom';
import {
  DescriptionNotificationStyled,
  ErrorCodeStyled,
  ForgotPasswordButtonStyled,
  FormBorderStyled,
  FormFooterStyled,
  FormItemStyled,
  FormStyled,
  FormTitleStyled,
  LoginFormButtonStyled,
  TitleNotificationStyled,
} from '../../common/styles';
import { ROUTER_PATH } from '../../router/path';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import React, { Suspense, useEffect } from 'react';
import { useResize } from '../../hooks/useResize';
import { useTranslation } from 'react-i18next';
import {
  IconNotificationError,
  IconNotificationSuccess,
} from '../../components/ui/icons';
import { CloseIcon } from '../../assets/img/icons/icons';
import { SupportEmail } from 'shared/ui/SupportEmail';

const LoginPage = () => {
  const url = window.location.href;
  const [form] = Form.useForm();
  const isErrorMaxCountSelectCode = useLoginState(
    (state) => state.isErrorMaxCountSelectCode
  );
  const setIsErrorMaxCountSelectCode = useLoginState(
    (state) => state.setIsErrorMaxCountSelectCode
  );
  const isErrorTimeoutCode = useLoginState((state) => state.isErrorTimeoutCode);
  const setIsErrorTimeoutCode = useLoginState(
    (state) => state.setIsErrorTimeoutCode
  );
  const login = useLoginState((state) => state.login);
  const setPassword = useLoginState((state) => state.setPassword);
  const setEmail = useLoginState((state) => state.setEmail);
  const reset = useLoginState((state) => state.reset);
  const { isScreen767, isScreen991 } = useResize();
  const restrictError = useLoginState((state) => state.restrictError);
  const setRestrictError = useLoginState((state) => state.setRestrictError);
  const isLoading = useLoginState((state) => state.isLoading);
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(window.location.search);
  const inviteToken = urlParams.get('inviteToken');

  const { t } = useTranslation();

  const navToRecoveryPassword = () =>
    navigate(ROUTER_PATH.FormsPages.RECOVER_PASSWORD);

  const navToRegister = () => navigate(ROUTER_PATH.FormsPages.REGISTER);
  const dropInvalidDataErrors = () => {
    form.setFields([
      {
        name: 'email',
        errors: [
          ...form
            .getFieldError('email')
            .filter(
              (err: string) =>
                err !== t('authorizationForms.invalidEmailPassword')
            ),
        ],
      },
      {
        name: 'password',
        errors: [
          ...form.getFieldError('password').filter((err: string) => !!err),
        ],
      },
    ]);
    setRestrictError(null);
  };

  function getRedirectUrl(url: string): string {
    const parsedUrl = new URL(url);
    return (
      parsedUrl.searchParams.get('redirect_url') ||
      localStorage.getItem('redirectUrl') ||
      ''
    );
  }

  function getToCreate(url: string): string {
    const parsedUrl = new URL(url);
    if (parsedUrl.searchParams.get('to_create')) {
      return 'true';
    }
    return 'false';
  }

  const handleSubmit = () => {
    form.validateFields().then(() => {
      login()
        .then(() => {
          navigate(`${ROUTER_PATH.FormsPages.LOGIN_CONFIRM}`);
          reset();
        })
        .catch((err) => {
          if (err.message?.includes('deleted')) {
            setRestrictError('deleted');
          }

          if (err.message?.includes('banned')) {
            setRestrictError('banned');
          }

          if (
            err?.data?.error_description?.includes(
              'Invalid user credentials'
            ) ||
            err?.data?.error_description?.includes(
              'Incorrect username or password or 2fa!'
            ) ||
            err?.message?.includes('Invalid username or password')
          ) {
            form.setFields([
              {
                name: 'email',
                errors: [t('authorizationForms.invalidEmailPassword')],
              },
              {
                name: 'password',
                errors: [''],
              },
            ]);
          }
        });
    });
  };

  const handleOpenNotification = () => {
    return !!(
      localStorage.getItem('email') &&
      localStorage.getItem('redirectUrl') &&
      localStorage.getItem('redirectUrl')?.includes('finmsapp') &&
      !localStorage.getItem('redirectUrl')?.includes('home') &&
      localStorage.getItem('toCreate') &&
      !JSON.parse(localStorage.getItem('toCreate')!) &&
      !!JSON.parse(localStorage.getItem('email_verified')!)
    );
  };

  useEffect(() => {
    localStorage.removeItem('loginHash');

    const redirectUrl = getRedirectUrl(url);
    const toCreate = getToCreate(url);

    const key = isErrorTimeoutCode
      ? `${isErrorTimeoutCode}`
      : `${isErrorMaxCountSelectCode}`;

    if (isErrorTimeoutCode || isErrorMaxCountSelectCode) {
      notification.error({
        icon: <IconNotificationError />,
        key,
        message: (
          <TitleNotificationStyled>
            {isErrorTimeoutCode
              ? t('Время действия кода истекло')
              : t('Превышено количество попыток ввода кода')}
          </TitleNotificationStyled>
        ),
        description: (
          <DescriptionNotificationStyled>
            {t('Авторизируйтесь повторно')}
          </DescriptionNotificationStyled>
        ),
        duration: 20000,
        style: { background: '#2B2B36' },
        closeIcon: <CloseIcon />,
        onClose: () => {
          setIsErrorTimeoutCode(false);
          setIsErrorMaxCountSelectCode(false);
        },
      });
    }

    if (handleOpenNotification()) {
      setTimeout(() => {
        notification.success({
          icon: <IconNotificationSuccess />,
          message: (
            <TitleNotificationStyled>
              {t('registerConfirmation.successMessage')}
            </TitleNotificationStyled>
          ),
          description: (
            <DescriptionNotificationStyled>
              {t('registerConfirmation.successDesc')}
            </DescriptionNotificationStyled>
          ),
          duration: 2,
          style: { background: '#2B2B36' },
          closeIcon: <CloseIcon />,
        });
      }, 500);
    }

    if (!!redirectUrl) {
      localStorage.setItem('redirectUrl', redirectUrl);
    }

    if (inviteToken) {
      localStorage.setItem('inviteToken', inviteToken);
    }

    localStorage.setItem('toCreate', toCreate);

    return () => {
      setIsErrorTimeoutCode(false);
      setIsErrorMaxCountSelectCode(false);
      notification.destroy(key);
    };
  }, []);

  return (
    <ErrorBoundary>
      <Suspense fallback={'Loading...'}>
        <FormBorderStyled $isScreen767={isScreen767} $isScreen991={isScreen991}>
          <FormTitleStyled>{t('authorizationForms.logIn')}</FormTitleStyled>
          {restrictError === 'banned' && (
            <ErrorCodeStyled>
              {t('Ваш аккаунт заблокирован запятая обратитесь в поддержку')}
            </ErrorCodeStyled>
          )}

          {restrictError === 'deleted' && (
            <ErrorCodeStyled>{t('Ваш аккаунт удалён')}</ErrorCodeStyled>
          )}
          <FormStyled
            layout={'vertical'}
            requiredMark={false}
            form={form}
            onChange={dropInvalidDataErrors}
          >
            <FormItemStyled
              label={t('authorizationForms.enterEmail')}
              name="email"
              rules={[
                {
                  type: 'email',
                  message: `${t('authorizationForms.invalidEmailFormat')}`,
                },
                {
                  required: true,
                  message: `${t('authorizationForms.enterEmail')}`,
                },
              ]}
            >
              <Input
                placeholder={t('authorizationForms.email').toString()}
                onChange={(e) => {
                  setEmail(e.target.value);
                  localStorage.setItem('loginEmail', e.target.value);
                }}
              />
            </FormItemStyled>

            <Form.Item
              name="password"
              label={`${t('authorizationForms.enterPassword')}`}
              rules={[
                {
                  required: true,
                  message: `${t('authorizationForms.enterPassword')}`,
                },
              ]}
            >
              <Input.Password
                placeholder={`${t('authorizationForms.password')}`}
                onChange={(e) => setPassword(e.target.value)}
                onPressEnter={handleSubmit}
              />
            </Form.Item>
            <ForgotPasswordButtonStyled
              type={'ghost'}
              onClick={navToRecoveryPassword}
            >
              {t('authorizationForms.forgotYourPassword')}
            </ForgotPasswordButtonStyled>
            <LoginFormButtonStyled loading={isLoading} onClick={handleSubmit}>
              {t('authorizationForms.come')}
            </LoginFormButtonStyled>
          </FormStyled>
          <FormFooterStyled $isScreen767={isScreen767}>
            <div>{t('authorizationForms.notAccount')}</div>
            <Button type={'ghost'} onClick={navToRegister}>
              {t('authorizationForms.createAccount')}
            </Button>
          </FormFooterStyled>
          <SupportEmail />
        </FormBorderStyled>
      </Suspense>
    </ErrorBoundary>
  );
};

export default LoginPage;

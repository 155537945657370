import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useCentrifugeState } from '../../state/centrifuge/useCentrifugeState';
import { RefereeChatWrapper } from '../../features/Chats/ui/RefereeChatWrapper/RefereeChatWrapper';
import NotFoundPage from '../errors/not-found';

enum QueryParamsReferee {
  TOKEN = 'refresh_token',
  OFFER_DEAL_ID = 'offer_deal_id',
}

export const RefereePage = () => {
  const [IsErrorParams, setIsErrorParams] = useState(false);

  const token =
    new URLSearchParams(window.location.search).get(QueryParamsReferee.TOKEN) ||
    localStorage.getItem('referee-token');

  const offer_deal_id = new URLSearchParams(window.location.search).get(
    QueryParamsReferee.OFFER_DEAL_ID
  );

  const refresh_token = new URLSearchParams(window.location.search).get(
    QueryParamsReferee.TOKEN
  );

  const centrifugeInstance = useCentrifugeState(
    (state) => state.centrifugeInstance
  );

  const createCentrifugeInstance = useCentrifugeState(
    (state) => state.createCentrifugeInstance
  );

  const connectToCentrifugeInstance = useCentrifugeState(
    (state) => state.connectToCentrifugeInstance
  );

  const disconnectCentrifugeInstance = useCentrifugeState(
    (state) => state.disconnectCentrifugeInstance
  );

  useEffect(() => {
    if (!token || !offer_deal_id) {
      setIsErrorParams(true);
    }
    if (refresh_token) {
      localStorage.setItem('referee_refresh_token', refresh_token);
    }
    createCentrifugeInstance(true);
    return () => {
      localStorage.removeItem('referee_uid');
      localStorage.removeItem('referee_token');
      localStorage.removeItem('referee_refresh_token');
      disconnectCentrifugeInstance();
    };
  }, []);

  useEffect(() => {
    if (centrifugeInstance) {
      connectToCentrifugeInstance();
    }
  }, [centrifugeInstance]);

  if (IsErrorParams) {
    return <NotFoundPage />;
  }

  return (
    <ChatContainerStyled>
      <RefereeChatWrapper dealID={offer_deal_id ?? ''} />
    </ChatContainerStyled>
  );
};

const ChatContainerStyled = styled.div`
  max-width: 800px;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  border-left: 1px solid #413d5080;
  border-right: 1px solid #413d5080;
`;

import {
  BadgeStyled,
  ChatItemWrapper,
  DirectionContainerStyled,
  TypographyStyled,
} from './ChatAsideListItem.style';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import Flex from '../../../../../shared/ui/Flex';
import CardTravel from '../../../../../assets/img/icons/card-travel.svg';
import UserOutlined from '../../../../../assets/img/icons/user-outlined.svg';
import Gavel from '../../../../../assets/img/icons/gavel.svg';
import { useChatAsideState } from '../../../model/useChatAsideState';
import { IChat } from '../../../../../interfaces';
import { useNavigate } from 'react-router-dom';
import { useChatListState } from '../../../../../pages/chat-list/state/useChatListState';
import { FINMS_URL } from '../../../../../packages/keycloak-client/constants';
import { useChatsState } from '../../../../../state/chats/useChatsState';

type ChatAsideListProps = {
  chat: IChat;
};

const ChatAsideListItem = ({ chat }: ChatAsideListProps) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const setIsOpenChat = useChatListState((state) => state.setIsOpenChat);

  const setOpenChatId = useChatListState((state) => state.setOpenChatId);
  const resetState = useChatsState((state) => state.resetState);
  const setOpenedEntity = useChatsState((state) => state.setOpenedEntity);

  const chatType = useChatAsideState((state) => state.chatType);

  const handleGoToChat = () => {
    if (chatType === 'personal') {
      if (chat.entity_type === 'offer_deal') {
        setOpenChatId(chat.id);
        navigate(`/deals/${chat.offer_deal_id}`);
      }
      if (chat.entity_type === 'offer') {
        resetState();
        navigate('/chat');
        setIsOpenChat(true);
        setOpenChatId(chat.id);
        setOpenedEntity('offer', chat.offer_id.toString(), chat.client_uid);
      }
    } else if (chatType === 'corporate') {
      if (chat.entity_type === 'offer_deal') {
        window.location.href = `${FINMS_URL}/offers/request/${chat.offer_deal_id}`;
      }
      if (chat.entity_type === 'offer') {
        resetState();
        navigate('/chat');
        setIsOpenChat(true);
        setOpenChatId(chat.id);
        setOpenedEntity('offer', chat.offer_id.toString(), chat.client_uid);
      }
    }
  };

  return (
    <ChatItemWrapper
      onClick={handleGoToChat}
      $is_buying_crypto={
        chatType === 'corporate'
          ? chat.offer?.is_buying_crypto
          : !chat.offer?.is_buying_crypto
      }
    >
      <DirectionContainerStyled gap={8} align={'flex-start'}>
        <Typography.Text strong>{`${
          chat.offer?.is_buying_crypto
            ? t(chatType === 'personal' ? 'Продажа' : 'Покупка')
            : t(chatType === 'personal' ? 'Покупка' : 'Продажа')
        } ${`${chat.offer?.crypto_payment_system_currency?.currency_key}-${chat.offer?.cash_currency_code}`}`}</Typography.Text>
        {chat.has_referee && <img src={Gavel} alt={'gavelIcon'} />}
      </DirectionContainerStyled>
      <Flex gap={4} vertical align={'flex-start'}>
        <Flex gap={8} align={'center'}>
          <div>
            <img src={CardTravel} alt={'cardTravelIcon'} />
          </div>
          <TypographyStyled>
            {chat.offer?.exchange_point?.name}
          </TypographyStyled>
        </Flex>
        {chatType === 'corporate' && (
          <Flex gap={8} align={'center'}>
            <div>
              <img src={UserOutlined} alt={'userOutlinedIcon'} />
            </div>
            <TypographyStyled>
              {chat.offer_deal?.nickname || chat?.client_nickname}
            </TypographyStyled>
          </Flex>
        )}
      </Flex>
      {!!chat.unread_count && (
        <BadgeStyled
          $is_corporate={chatType === 'corporate'}
          count={chat.unread_count}
          color={'linear-gradient(45deg, #088473 0%, #12BFA8 100%)'}
        />
      )}
    </ChatItemWrapper>
  );
};

export default ChatAsideListItem;

import React from 'react';
import { RouterProvider } from './common/router';
import { ROUTES } from './router';
import FadeWrapper from './components/FadeWrapper';

function App() {
  const loader = document.querySelector('.ant-spin');

  if (loader) {
    loader.remove();
  }

  return (
    <FadeWrapper isVisible={true}>
      <RouterProvider routes={ROUTES} />
    </FadeWrapper>
  );
}

export default App;

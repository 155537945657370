import { axios } from '../../../shared/exios';
import { FINMS_URL_API } from '../../../packages/keycloak-client/constants';
import { IClient, IMeta } from '../../../interfaces';
import { IClientFilter } from '../model/types';

export const getClients = async (): Promise<IClientFilter> => {
  try {
    const { data } = await axios.post<{ data: IClient[]; meta: IMeta }>(
      `${FINMS_URL_API}/server/api/clients/search`,
      {
        limit: 200,
        page: 1,
      },
      {}
    );
    return {
      data: data.data,
      type: 'client',
      meta: data.meta,
    };
  } catch {
    return {
      data: [],
      type: 'client',
      meta: {
        current_page: 1,
        from: 0,
        last_page: 0,
        path: '',
        per_page: 0,
        to: 0,
        total: 0,
      },
    };
  }
};

import styled, { css } from 'styled-components';
import { getTime } from 'shared/helpers/getTime';
import { getTimeWithDate } from 'shared/helpers/getTimeWithDate';

interface ITimeMark {
  date: Date;
  onlyTime?: boolean;
  bigSize?: boolean;
}

const TimeMark = ({ date, onlyTime = false, bigSize = false }: ITimeMark) => {
  return (
    <TimeMarkStyled $bigSize={bigSize}>
      {onlyTime ? getTime(date) : getTimeWithDate(date)}
    </TimeMarkStyled>
  );
};

export { TimeMark };

const badgeStyles = css`
  color: rgba(118, 118, 135, 1);
  font-size: 12px;
`;

const otherStyles = css`
  color: rgba(195, 195, 206, 1);
  font-size: 10px;
`;

const TimeMarkStyled = styled.div<{ $bigSize: boolean }>`
  ${(props) => (props.$bigSize ? badgeStyles : otherStyles)}
`;

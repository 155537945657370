import styled from 'styled-components';
import { Modal } from 'antd';

export const ModalStyled = styled(Modal)`
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100vw !important;
  min-height: 100vh !important;

  & .ant-modal-content {
    height: 100vh !important;
    background: none !important;
  }

  & .ant-modal-body {
    height: 100% !important;
  }

  & .ant-modal-footer {
    display: none !important;
  }

  & .ant-modal-close {
    width: 16px !important;
    height: 16px !important;
    position: absolute !important;
    top: 96px !important;
    right: 80px !important;
    color: white !important;
  }
`;

export const LinkStyled = styled.a`
  color: white;
`;

import { Centrifuge } from 'centrifuge';
import { create } from 'zustand';
import { produce } from 'immer';
import { devtools } from 'zustand/middleware';
import {
  centrifugeInstanceConnect,
  centrifugeInstanceCreate,
  centrifugeInstanceDisconnect,
} from 'shared/api/CentrifugeAPI/CentrifugeAPI';

type ICentrifugeState = {
  centrifugeInstance: Centrifuge | null;
  centrifugeInstanceState: 'connected' | 'disconnected' | 'connecting';
  createCentrifugeInstance: (isReferee?: boolean) => void;
  connectToCentrifugeInstance: () => void;
  disconnectCentrifugeInstance: () => void;
};

export const useCentrifugeState = create<ICentrifugeState>()(
  devtools(
    (set, get) => ({
      centrifugeInstance: null,
      centrifugeInstanceState: 'disconnected',
      createCentrifugeInstance: (isReferee?: boolean) => {
        let centrifugeInstance: Centrifuge | null;
        if (isReferee) {
          centrifugeInstance = centrifugeInstanceCreate(isReferee);
          set(
            produce((draft) => {
              draft.centrifugeInstance = centrifugeInstance;
            })
          );
        }
        if (!isReferee) {
          centrifugeInstance = centrifugeInstanceCreate();
          set(
            produce((draft) => {
              draft.centrifugeInstance = centrifugeInstance;
            })
          );
        }
      },
      connectToCentrifugeInstance: () => {
        const status = centrifugeInstanceConnect();
        set(
          produce((draft) => {
            draft.centrifugeInstanceState = status;
          })
        );
      },
      disconnectCentrifugeInstance: () => {
        const status = centrifugeInstanceDisconnect();
        set(
          produce((draft) => {
            draft.centrifugeInstanceState = status;
          })
        );
      },
    }),
    {
      name: 'useCentrifugeState',
      anonymousActionType: 'useCentrifugeState action',
    }
  )
);

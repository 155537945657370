import React from 'react';
import { ConfigProvider, TabsProps, ThemeConfig } from 'antd';
import { useTranslation } from 'react-i18next';
import { BadgeStyled, TabsStyled } from './ChatTabs.style';
import { UserOutlined } from '@ant-design/icons';
import CardTravel from '../../../../../assets/img/icons/card-travel-white-large.svg';
import CardTravelBlue from '../../../../../assets/img/icons/card-travel-blue-large.svg';
import { TChatType, useChatAsideState } from '../../../model/useChatAsideState';
import { useChatsState } from '../../../../../state/chats/useChatsState';

const config: ThemeConfig = {
  token: {
    colorPrimary: '#1890ff',
  },
};

export const NotificationAsideTabs = () => {
  const { t } = useTranslation();

  const chatType = useChatAsideState((state) => state.chatType);

  const changeChatType = useChatAsideState((state) => state.changeChatType);

  const fetchChatsAfterSwitch = useChatAsideState(
    (state) => state.fetchChatsAfterSwitch
  );

  const { clientCount } = useChatsState((state) => state.unreadMessagesCounts);

  const { corporateCount } = useChatsState(
    (state) => state.unreadMessagesCounts
  );

  const handleSwitchTab = (tab: string) => {
    changeChatType(tab as TChatType);
    fetchChatsAfterSwitch();
  };

  const items: TabsProps['items'] = [
    {
      key: 'personal',
      label: (
        <BadgeStyled count={clientCount}>
          <UserOutlined />
          <div>{t('Личные')}</div>
        </BadgeStyled>
      ),
    },
    {
      key: 'corporate',
      label: (
        <BadgeStyled count={corporateCount}>
          <img src={chatType === 'corporate' ? CardTravelBlue : CardTravel} />
          <div>{t('Корпоративные')}</div>
        </BadgeStyled>
      ),
    },
  ];

  return (
    <ConfigProvider theme={config}>
      <TabsStyled
        activeKey={chatType}
        items={items}
        onChange={handleSwitchTab}
      />
    </ConfigProvider>
  );
};

import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import Styles from '../../../index.module.css';
import React from 'react';
import styled from 'styled-components';

type TImageFileBlock = {
  previewImage: string;
  handleDeleteFile: () => void;
};

const ImageFileBlock = ({ previewImage, handleDeleteFile }: TImageFileBlock) => {
  return (
    <ImageFileWrapperStyled>
      <ImagePreviewStyled alt="imgPreview" src={previewImage} />
      <ButtonStyled
        size="small"
        icon={<CloseOutlined className={Styles.file_preview__icon} />}
        disabled={false}
        onClick={handleDeleteFile}
      />
    </ImageFileWrapperStyled>
  );
};

const ImageFileWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  border-radius: 8px;
  position: relative;
`;

const ImagePreviewStyled = styled.img`
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
`;

const ButtonStyled = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  border: 1px solid #00000026;
  box-shadow: 0 2px 0 0 #ffffff0a;
`;

export default ImageFileBlock;

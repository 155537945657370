import { LogoMini } from '../icons';
import React from 'react';
import { useResize } from '../../../hooks/useResize';
import {
  HeaderInnerStyled,
  LinksHeaderStyled,
  WrapperLanguageSelectStyled,
  WrapperLogoMiniStyled,
} from './styles';

const LanguageSelect = React.lazy(
  () =>
    import(
      './../../tools/layouts/components/header/components/language-select/index'
    )
);

const Header = () => {
  const { isScreen767 } = useResize();

  return (
    <HeaderInnerStyled>
      {!isScreen767 ? (
        <WrapperLogoMiniStyled>
          <LogoMini />
        </WrapperLogoMiniStyled>
      ) : (
        <LinksHeaderStyled></LinksHeaderStyled>
      )}
      {isScreen767 ? (
        <WrapperLanguageSelectStyled>
          <LanguageSelect />
        </WrapperLanguageSelectStyled>
      ) : null}
    </HeaderInnerStyled>
  );
};

export { Header };

import { useEffect } from 'react';
import { useChatsState } from '../../../../state/chats/useChatsState';
import { RefereeChatBody } from './RefereeChatBody';
import { RefereeChatFooter } from './RefereeChatFooter';
import { RefereeChatHeader } from './RefereeChatHeader';
import { RefereeChatContainerStyled } from './RefereeChatStyles';

type OfferRequestChatWrapperProps = {
  dealID: string;
};

export const RefereeChatWrapper = ({
  dealID,
}: OfferRequestChatWrapperProps) => {
  const setOpenedEntity = useChatsState((state) => state.setOpenedEntity);

  const currentChatInfo = useChatsState((state) => state.currentChatInfo);
  const getChatInfo = useChatsState((state) => state.getChatInfo);
  const resetState = useChatsState((state) => state.resetState);

  useEffect(() => {
    setOpenedEntity('offer_deal', dealID);
    return () => {
      setOpenedEntity(null, null, null);
    };
  }, [dealID]);

  useEffect(() => {
    getChatInfo(dealID.toString(), 'referee');
    return () => {
      resetState();
    };
  }, [dealID]);

  return (
    <RefereeChatContainerStyled>
      <RefereeChatHeader dealID={dealID.toString()} />
      <RefereeChatBody
        dealID={dealID.toString()}
        clientUid={currentChatInfo.client_uid}
      />
      <RefereeChatFooter dealID={dealID.toString()} />
    </RefereeChatContainerStyled>
  );
};

import React, { useEffect } from 'react';
import './NotificationsAsideBell.css';
import { BellOutlined } from '@ant-design/icons';
import { DropdownStyled } from './NotificationAsideBell.style';
import { NotificationAside } from '../NotificationAside/NotificationAside';
import { useNotificationAsideState } from '../../model/useNotificationAsideState';
import { getCookieByName } from '../../../../shared/helpers/controlCookies';
import jwtDecode from 'jwt-decode';
import { Badge } from 'antd';
import { useCentrifugeState } from '../../../../state/centrifuge/useCentrifugeState';

export const NotificationsAsideBell = () => {
  const totalCount = useNotificationAsideState(
    (state) => state.totalNotificationsCount
  );

  const centrifugeInstance = useCentrifugeState(
    (state) => state.centrifugeInstance
  );

  const setNotifications = useNotificationAsideState(
    (state) => state.setNotifications
  );
  const setTotalNotificationsCount = useNotificationAsideState(
    (state) => state.setTotalNotificationsCount
  );

  const connectToNotificationWs = () => {
    const decodedToken: any = jwtDecode(getCookieByName('token') || '');
    if (centrifugeInstance) {
      centrifugeInstance.on('publication', (ctx) => {
        if (ctx.channel === decodedToken.sub && decodedToken) {
          setNotifications();
          setTotalNotificationsCount();
        }
      });
    }
  };

  useEffect(() => {
    connectToNotificationWs();
    setTotalNotificationsCount();
  }, []);

  return (
    <DropdownStyled
      trigger={['click']}
      className={'ant-dropdown-notifications-custom'}
      dropdownRender={() => <NotificationAside />}
    >
      <Badge
        count={totalCount}
        size={'default'}
        color={'linear-gradient(45deg, #088473 0%, #12BFA8 100%)'}
        showZero={true}
        offset={[-1, 1]}
      >
        <BellOutlined />
      </Badge>
    </DropdownStyled>
  );
};

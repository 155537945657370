import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { EDENEX_ACCOUNT_URL } from '../packages/keycloak-client/constants';
import { produce } from 'immer';
import { default as defaultAxios } from 'axios';
import { TDecodedToken, useAuthState } from './useAuthState';
import jwtDecode from 'jwt-decode';
import { setCookieByName } from '../shared/helpers/controlCookies';

type TLoginState = {
  login: () => Promise<void>;
  setEmail: (value: string) => void;
  setPassword: (value: string) => void;
  reset: () => void;
  password: string;
  email: string;
  token: string;
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
  restrictError: null | 'banned' | 'deleted';
  setRestrictError: (error: null | 'banned' | 'deleted') => void;
  getSessionTokenByHash: (totp: string) => Promise<void>;
  isErrorMaxCountSelectCode: boolean;
  setIsErrorMaxCountSelectCode: (value: boolean) => void;
  isErrorTimeoutCode: boolean;
  setIsErrorTimeoutCode: (value: boolean) => void;
};

export const useLoginState = create<TLoginState>()(
  devtools(
    (set, get) => ({
      password: '',
      email: localStorage.getItem('loginEmail') || '',
      token: '',
      isLoading: false,
      restrictError: null,
      isErrorMaxCountSelectCode: false,
      isErrorTimeoutCode: false,
      setIsErrorMaxCountSelectCode: (value: boolean) => {
        set(
          produce((draft) => {
            draft.isErrorMaxCountSelectCode = value;
          })
        );
      },
      setIsErrorTimeoutCode: (value: boolean) => {
        set(
          produce((draft) => {
            draft.isErrorTimeoutCode = value;
          })
        );
      },

      getSessionTokenByHash: async (totp: string) => {
        if (!totp) return Promise.reject();

        try {
          const token = localStorage.getItem('loginHash');

          if (!token) return;

          const res = await defaultAxios.get(
            `${EDENEX_ACCOUNT_URL}/edenex-account/api/get-token`,
            {
              params: {
                token,
                code: totp,
              },
            }
          );

          const { success, data } = res?.data || {};
          if (success) {
            const RefreshToken = data?.refresh_token;
            const AccessToken = data?.access_token;
            const decodedToken: TDecodedToken = jwtDecode(AccessToken);

            setCookieByName('refresh_token', RefreshToken);
            setCookieByName('token', AccessToken);
            setCookieByName('email', decodedToken?.email);
            setCookieByName('uid', decodedToken?.sub);

            useAuthState.getState().setIsAuth(true);

            return Promise.resolve();
          }
        } catch (e) {
          console.log('getSessionByHashToken error', e);
          return Promise.reject(e);
        }
      },
      setRestrictError: (error: null | 'banned' | 'deleted') => {
        set(
          produce((draft) => {
            draft.restrictError = error;
          })
        );
      },
      setIsLoading: (value: boolean) => {
        set(
          produce((draft) => {
            draft.isLoading = value;
          })
        );
      },
      login: async () => {
        try {
          useLoginState.getState().setIsLoading(true);

          const data = {
            client_id: 'crypto.web',
            grant_type: 'password',
            username: get().email,
            password: get().password,
          };

          const res = await defaultAxios.post(
            `${EDENEX_ACCOUNT_URL}/edenex-account/api/login`,
            data
          );
          const {
            success,
            data: { hash },
          } = res?.data;

          if (success) {
            localStorage.setItem('loginHash', hash);

            set(
              produce((draft) => {
                draft.isLoading = true;
                draft.reset();
              })
            );
          }
        } catch (e: any) {
          console.log('login error', e);

          return Promise.reject(e.response.data);
        } finally {
          set(
            produce((draft) => {
              draft.isLoading = false;
            })
          );
        }
      },
      setEmail: (value: string) => {
        set(
          produce((draft) => {
            draft.email = value;
          }),
          false,
          {
            type: 'useLoginState => setEmail',
          }
        );
      },
      setPassword: (value: string) => {
        set(
          produce((draft) => {
            draft.password = value;
          }),
          false,
          {
            type: 'useLoginState => setPassword',
          }
        );
      },
      reset: () => {
        set(
          produce((draft) => {
            draft.password = '';
            draft.email = '';
          }),
          false,
          {
            type: 'useLoginState => reset',
          }
        );
      },
    }),
    {
      anonymousActionType: 'useLoginState action',
      name: 'useLoginState',
    }
  )
);

import { useRegisterState } from '../../state/useRegisterState';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTER_PATH } from '../../router/path';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { IconNotificationSuccess } from '../../components/ui/icons';
import { CloseIcon } from '../../assets/img/icons/icons';

const RegisterConfirm = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const { t } = useTranslation();
  const registerConfirm = useRegisterState((state) => state.registerConfirm);
  const setEmail = useRegisterState((state) => state.setEmail);
  const navigate = useNavigate();
  const inviteToken = urlParams.get('inviteToken');

  const getQueryToken = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const redirectUrl = urlParams.get('redirect_url');
    if (!!token) {
      if (!!redirectUrl && !!inviteToken) {
        navigate(
          `${ROUTER_PATH.FormsPages.CONFIRM_INVITE}?redirect_url=${redirectUrl}/redirect-page&inviteToken=${inviteToken}`
        );
      } else if (!!redirectUrl && !inviteToken) {
        navigate(`${ROUTER_PATH.FormsPages.LOGIN}?redirect_url=${redirectUrl}`);
      } else {
        navigate(ROUTER_PATH.FormsPages.LOGIN);
      }

      registerConfirm({ token }).then(() => {
        if (inviteToken) {
          notification.info({
            icon: <IconNotificationSuccess />,
            message: (
              <span
                style={{
                  fontSize: '16px',
                  fontWeight: '700',
                  color: '#FBFBFF',
                }}
              >
                {t('registerConfirmation.successMessage')}
              </span>
            ),
            description: (
              <span style={{ color: '#FBFBFF' }}>
                {t('descriptions.registrationInviteLogIn')}
              </span>
            ),
            duration: 3,
            style: { background: '#2B2B36' },
            closeIcon: <CloseIcon />,
          });
        }
      });
    }
  };

  const setEmailToState = () => {
    const email = localStorage.getItem('loginEmail');

    if (email) {
      setEmail(email);
    }
  };

  useEffect(() => {
    setEmailToState();
    getQueryToken();
  }, []);

  return <></>;
};

export default RegisterConfirm;

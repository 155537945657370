import { CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import {
  ChatListHeaderStyled,
  ChatListHeaderTitleStyled,
  CloseButton,
} from './assets/styles';
import { useEffect } from 'react';
import { useChatListState } from '../../state/useChatListState';

type TChatListHeaderProps = {
  onCrossClick: () => void;
};

const ChatListHeader = ({ onCrossClick }: TChatListHeaderProps) => {
  const { t } = useTranslation();

  const setCurrentPage = useChatListState((state) => state.setCurrentPage);

  useEffect(() => {
    return () => {
      setCurrentPage(1);
    };
  }, []);

  return (
    <ChatListHeaderStyled>
      <ChatListHeaderTitleStyled>{t(`Чаты`)}</ChatListHeaderTitleStyled>
      <CloseButton
        type={'text'}
        icon={<CloseOutlined />}
        onClick={onCrossClick}
      />
    </ChatListHeaderStyled>
  );
};

export default ChatListHeader;

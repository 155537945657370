import styled from 'styled-components';

export const RefereeChatContainerStyled = styled.div`
  font-family: Inter, sans-serif;
  position: relative;
  background-color: #14141f;
  color: white;
  font-size: 14px;
  line-height: 20px;
  overflow: hidden;

  height: 99vh;
  margin-bottom: auto;

  display: flex;
  flex-direction: column;
`;

// ---------- header ----------

export const RefereeChatHeaderStyled = styled.div`
  position: relative;
  border-top: 1px solid #413d50;
  border-bottom: 1px solid #413d50;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: stretch;
  padding: 24px;
`;

export const IconContainerStyled = styled.div`
  svg {
    height: 24px;
    width: 24px;
  }
`;

// ---------- body ----------

export const RefereeChatBodyStyled = styled.div`
  flex: 1;
  overflow-y: auto;
  word-break: break-all;
  display: flex;
  flex-direction: column;
  margin: 16px 24px 0;
  height: 100%;
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a98d8d2d;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(169, 141, 141, 0.22);
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
`;

export const RefereeSpinContainerStyled = styled.div`
  display: flex;
  width: auto;
  height: auto;
  max-height: 100%;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  .ant-spin-dot-item {
    background: #1668dc;
  }
`;

// ---------- footer ----------

export const RefereeChatFooterStyled = styled.div`
  min-height: 56px;
  margin: 0 24px 24px;
`;

import { StatusTag } from '../../../../components/ui/tags/status-tag';
import { DoorArrow } from '../../../../assets/img/icons/icons';
import React, { memo } from 'react';
import { ErrorBoundary } from '../../../../components/ErrorBoundary';
import { t } from 'i18next';

type TStatusTagLastLogin = {
  date: string;
};

const StatusTagLastLogin = memo(({ date }: TStatusTagLastLogin) => {
  return (
    <ErrorBoundary>
      <StatusTag
        preText={t('titles.lastLogin').toString()}
        isConnected={false}
        noIcon={true}
      >
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <span
            style={{
              padding: '0 10px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <DoorArrow />
          </span>
          {date}
        </span>
      </StatusTag>
    </ErrorBoundary>
  );
});

export default StatusTagLastLogin;

import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';

import { AvatarStyled, ChatHeaderMainInfoStyled } from '../Chat.styles';
import { CardTravelIcon } from '../../../../assets/img/icons/icons';
import { UserOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { Tooltip } from 'antd';
import {
  ChatHeaderInfoTempTimerStyled,
  OfferChatHeaderStyled,
  OfferChatHeaderSubInfoStyled,
  TimerContainerStyled,
} from './OfferChatStyles';

type ChatHeaderProps = {
  exchangePointName: string;
  clientName: string;
  role: 'user' | 'admin';
  chatCreatedDate: string;
  offerID: string | null;
};

export const OfferChatHeader = ({
  exchangePointName,
  clientName,
  role,
  chatCreatedDate,
  offerID,
}: ChatHeaderProps) => {
  const { t } = useTranslation();

  const [deadline, setDeadline] = useState('');

  useEffect(() => {
    if (chatCreatedDate) {
      const date = dayjs(chatCreatedDate);
      const result = date.add(1, 'day').toISOString();
      setDeadline(result);
    }
    return () => {
      setDeadline('');
    };
  }, [offerID, chatCreatedDate]);

  return (
    <OfferChatHeaderStyled>
      <ChatHeaderMainInfoStyled>
        {role === 'admin' ? (
          <ChatHeaderMainInfoStyled>
            <AvatarStyled>
              <UserOutlined />
            </AvatarStyled>
            <div>{clientName}</div>
          </ChatHeaderMainInfoStyled>
        ) : (
          <>
            <AvatarStyled>
              <CardTravelIcon />
            </AvatarStyled>
            <div>{exchangePointName}</div>
          </>
        )}
      </ChatHeaderMainInfoStyled>
      {deadline !== '' && (
        <OfferChatHeaderSubInfoStyled>
          <Tooltip
            trigger={'hover'}
            title={t('чат.Таймер тултип')}
            placement="bottomLeft"
          >
            <TimerContainerStyled>
              <ChatHeaderInfoTempTimerStyled
                value={deadline}
                format={`H ${t('чат.ч')} : m`}
              />
              <span>
                {t('чат.м')} {t('чат.временный чат')}
              </span>
            </TimerContainerStyled>
          </Tooltip>
        </OfferChatHeaderSubInfoStyled>
      )}
    </OfferChatHeaderStyled>
  );
};

import { devtools } from 'zustand/middleware';
import { create } from 'zustand';
import { produce } from 'immer';
import {
  IChat,
  IMessage,
  RolesType,
  SearchAttachmentsPayloadType,
  UploadAttachmentToMinioType,
} from '../../shared/api/ChatsAPI/ChatsTypes';
import {
  getChatInfo_user,
  getChatInfo_referee,
  getUnreadMessagesCount,
  postAttachmentStep2,
  getMessages_user,
  getMessages_referee,
  getMessages_offer,
  sendMessage_offer,
  sendMessage_user,
  sendMessage_referee,
  postAttachmentStep1_user,
  postAttachmentStep3_user,
  getOfferChatInfo_admin,
  getOfferChatInfo_client,
  postAttachmentStep3_offer,
  postAttachmentStep1_offer,
  postAttachmentStep1_referee,
  postAttachmentStep3_referee,
} from '../../shared/api/ChatsAPI/ChatsAPI';

type IChatsState = {
  openedEntity: {
    entityType: 'offer' | 'offer_deal' | null;
    id: string | null;
    clientUid: string | null;
  };
  setOpenedEntity: (
    entityType: 'offer' | 'offer_deal' | null,
    id: string | null,
    clientUid?: string | null
  ) => void;
  unreadMessagesCounts: {
    clientCount: number;
    corporateCount: number;
  };
  setUnreadMessagesCount: () => void;
  isLoadingMessages: boolean;
  currentMessages: IMessage[];
  resetState: () => void;
  getMessagesOffer: (
    offerID: string,
    role: RolesType,
    clientUid: string
  ) => void;
  getMessages: (dealID: string, role: RolesType) => void;
  sendMessage: (
    dealID: string,
    message: string,
    hasAttachment: boolean,
    role: RolesType
  ) => any;
  sendMessageOffer: (
    offerID: string,
    message: string,
    hasAttachment: boolean,
    role: RolesType,
    clientUid: string
  ) => any;
  addNewMessageFromWS: (message: IMessage) => void;
  currentChatInfo: IChat;
  getChatInfo: (dealID: string, role: RolesType, uid?: string) => void;
  getOfferChatInfo: (offerID: string, uid: string, role: RolesType) => any;
  postAttachmentStep1Offer: (
    payload: SearchAttachmentsPayloadType,
    role: RolesType
  ) => any;
  postAttachmentStep3Offer: (
    attachment_id: string | number | null,
    role: RolesType
  ) => any;
  postAttachmentStep1User: (payload: SearchAttachmentsPayloadType) => any;
  postAttachmentStep1Referee: (payload: SearchAttachmentsPayloadType) => any;
  postAttachmentStep3User: (attachment_id: string | number | null) => any;
  postAttachmentStep3Referee: (attachment_id: string | number | null) => any;
  // общий шаг
  postAttachmentStep2: (payload: UploadAttachmentToMinioType) => any;
};

export const useChatsState = create<IChatsState>()(
  devtools(
    (set, get) => ({
      openedEntity: {
        entityType: null,
        id: null,
        clientUid: null,
      },
      setOpenedEntity: (
        entityType: 'offer' | 'offer_deal' | null,
        id: string | null,
        clientUid?: string | null
      ) => {
        set(
          produce((draft) => {
            draft.openedEntity = {
              entityType: entityType,
              id: id,
              clientUid: clientUid,
            };
          })
        );
      },
      unreadMessagesCounts: {
        clientCount: 0,
        corporateCount: 0,
      },
      currentMessages: [] as IMessage[],
      currentChatInfo: {} as IChat,
      isLoadingMessages: true,
      resetState: () => {
        set({
          unreadMessagesCounts: {
            clientCount: 0,
            corporateCount: 0,
          },
          currentMessages: [],
          currentChatInfo: {} as IChat,
          isLoadingMessages: true,
        });
      },
      setUnreadMessagesCount: async () => {
        let response = await getUnreadMessagesCount();
        set(
          produce((draft) => {
            draft.unreadMessagesCounts.corporateCount =
              response?.data?.exchange_point?.exchange_points.reduce(
                (prev, next) => prev + next.unread_message_count,
                0
              ) || 0;
            draft.unreadMessagesCounts.clientCount = response?.data?.user
              .unread_message_count
              ? response?.data?.user.unread_message_count
              : 0;
          })
        );
      },
      getMessages: async (dealID: string, role: RolesType) => {
        try {
          set(
            produce((draft) => {
              draft.isLoadingMessages = true;
            })
          );
          if (role === 'user') {
            let response = await getMessages_user('offer_deal', dealID);
            // убрать filter когда появятся системные сообщения
            set(
              produce((draft) => {
                draft.currentMessages = response?.data?.data.filter(
                  (message: IMessage) => !message.is_system
                );
              })
            );
          }
          if (role === 'referee') {
            let response = await getMessages_referee('offer_deal', dealID);
            // убрать filter когда появятся системные сообщения
            set(
              produce((draft) => {
                draft.currentMessages = response?.data?.data.filter(
                  (message: IMessage) => !message.is_system
                );
              })
            );
          }
        } catch (error) {
          console.log(error);
        } finally {
          set(
            produce((draft) => {
              draft.isLoadingMessages = false;
            })
          );
        }
      },
      getMessagesOffer: async (offerID: string, role: RolesType, clientUid) => {
        try {
          set(
            produce((draft) => {
              draft.isLoadingMessages = true;
            })
          );
          let response = await getMessages_offer(
            'offer',
            clientUid,
            role,
            offerID
          );
          // убрать filter когда появятся системные сообщения
          set(
            produce((draft) => {
              draft.currentMessages = response?.data?.data.filter(
                (message: IMessage) => !message.is_system
              );
            })
          );
        } catch (error) {
          console.log(error);
        } finally {
          set(
            produce((draft) => {
              draft.isLoadingMessages = false;
            })
          );
        }
      },
      sendMessage: async (
        dealID: string,
        message: string,
        hasAttachment: boolean,
        role: RolesType
      ) => {
        if (role === 'user') {
          return await sendMessage_user(
            'offer_deal',
            dealID,
            message,
            hasAttachment
          );
        }
        if (role === 'referee') {
          return await sendMessage_referee(
            'offer_deal',
            dealID,
            message,
            hasAttachment
          );
        }
      },
      sendMessageOffer: async (
        offerID: string,
        message: string,
        hasAttachment: boolean,
        role: RolesType,
        clientUid: string
      ) => {
        return await sendMessage_offer(
          'offer',
          offerID,
          message,
          hasAttachment,
          role,
          clientUid
        );
      },
      addNewMessageFromWS: (message: IMessage) => {
        if (
          get().currentMessages?.length &&
          get().currentMessages[
            get().currentMessages?.length - 1
          ]?.id?.toString() === message.id.toString()
        ) {
          return;
        } else {
          set(
            produce((draft) => {
              draft.currentMessages.push(message);
            })
          );
        }
      },
      getChatInfo: async (dealID: string, role: RolesType, uid?: string) => {
        try {
          if (role === 'user' && uid) {
            let response = await getChatInfo_user('offer_deal', dealID, uid);
            set(
              produce((draft) => {
                draft.currentChatInfo = response?.data?.data;
              })
            );
          }
          if (role === 'referee') {
            let response = await getChatInfo_referee('offer_deal', dealID);
            set(
              produce((draft) => {
                draft.currentChatInfo = response?.data?.data;
              })
            );
          }
        } catch (error) {
          console.log(error);
        }
      },
      getOfferChatInfo: async (
        offerID: string,
        uid: string,
        role: RolesType
      ) => {
        try {
          if (role === 'user' && uid) {
            let response = await getOfferChatInfo_client('offer', offerID, uid);
            set(
              produce((draft) => {
                draft.currentChatInfo = response?.data?.data;
              })
            );
            return response;
          }
          if (role === 'exchange_point' && uid) {
            let response = await getOfferChatInfo_admin('offer', offerID, uid);
            set(
              produce((draft) => {
                draft.currentChatInfo = response?.data?.data;
              })
            );
            return response;
          }
        } catch (error) {
          console.log(error);
        }
      },
      postAttachmentStep2: async (payload: UploadAttachmentToMinioType) => {
        // step 2 - upload attachment to minio
        try {
          const res = await postAttachmentStep2(payload);
          if (res) {
            return Promise.resolve();
          }
        } catch (e) {
          console.log(e);
          return Promise.reject();
        }
      },
      postAttachmentStep1User: async (
        payload: SearchAttachmentsPayloadType
      ) => {
        // step 1 - create attachment
        try {
          const res = await postAttachmentStep1_user(payload);
          return Promise.resolve({
            lastUploadLink: res?.data?.data?.upload_link,
            lastCreatedAttachmentId: res?.data?.data?.id,
          });
        } catch (e) {
          console.log(e);
          return Promise.reject();
        }
      },
      postAttachmentStep1Referee: async (
        payload: SearchAttachmentsPayloadType
      ) => {
        // step 1 - create attachment
        try {
          const res = await postAttachmentStep1_referee(payload);
          return Promise.resolve({
            lastUploadLink: res?.data?.data?.upload_link,
            lastCreatedAttachmentId: res?.data?.data?.id,
          });
        } catch (e) {
          console.log(e);
          return Promise.reject();
        }
      },
      postAttachmentStep1Offer: async (
        payload: SearchAttachmentsPayloadType,
        role: RolesType
      ) => {
        // step 1 - create attachment
        try {
          const res = await postAttachmentStep1_offer(payload, role);
          return Promise.resolve({
            lastUploadLink: res?.data?.data?.upload_link,
            lastCreatedAttachmentId: res?.data?.data?.id,
          });
        } catch (e) {
          console.log(e);
          return Promise.reject();
        }
      },
      postAttachmentStep3User: async (
        attachment_id: string | number | null
      ) => {
        // step 3 - change is upload in attachment
        try {
          await postAttachmentStep3_user(attachment_id);
          return Promise.resolve();
        } catch (e) {
          console.log(e);
          return Promise.reject();
        }
      },
      postAttachmentStep3Referee: async (
        attachment_id: string | number | null
      ) => {
        // step 3 - change is upload in attachment
        try {
          await postAttachmentStep3_referee(attachment_id);
          return Promise.resolve();
        } catch (e) {
          console.log(e);
          return Promise.reject();
        }
      },
      postAttachmentStep3Offer: async (
        attachment_id: string | number | null,
        role: RolesType
      ) => {
        // step 3 - change is upload in attachment
        try {
          await postAttachmentStep3_offer(attachment_id, role);
          return Promise.resolve();
        } catch (e) {
          console.log(e);
          return Promise.reject();
        }
      },
    }),
    {
      name: 'useCentrifugeState',
      anonymousActionType: 'useCentrifugeState action',
    }
  )
);

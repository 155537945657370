import {
  ChatAsideContainerStyled,
  ChatAsideFooterStyled,
  ChatAsideHeaderStyled,
  ChatAsideLinkStyled,
  ChatAsideTitleStyled,
} from './ChatAside.style';
import { useEffect, useMemo } from 'react';
import { ChatAsideList } from '../ChatAsideList/ChatAsideList';
import { useTranslation } from 'react-i18next';
import { NotificationAsideTabs } from './ChatTabs/ChatTabs';
import { useChatAsideState } from '../../model/useChatAsideState';

export const ChatAside = () => {
  const { t } = useTranslation();

  const personalChatsList = useChatAsideState(
    (state) => state.personalChatList
  );

  const corporateChatsList = useChatAsideState(
    (state) => state.corporateChatList
  );

  const chatType = useChatAsideState((state) => state.chatType);

  const fetchChats = useChatAsideState((state) => state.fetchChats);

  const resetChatLists = useChatAsideState((state) => state.resetChatLists);

  const chatList = useMemo(() => {
    const currentChatList =
      chatType === 'personal' ? personalChatsList : corporateChatsList;
    return {
      chats: currentChatList.data,
      totalChats: currentChatList.totalPages,
      totalItems: currentChatList.totalItems,
    };
  }, [chatType, personalChatsList.data, corporateChatsList.data]);

  useEffect(() => {
    fetchChats();
    return () => {
      resetChatLists();
    };
  }, []);

  return (
    <ChatAsideContainerStyled>
      <ChatAsideHeaderStyled>
        <ChatAsideTitleStyled>{t('Чаты')}</ChatAsideTitleStyled>
        <NotificationAsideTabs />
      </ChatAsideHeaderStyled>
      <ChatAsideList {...chatList} />
      <ChatAsideFooterStyled>
        <ChatAsideLinkStyled to={'/chat'}>
          {t('Смотреть все чаты')}
        </ChatAsideLinkStyled>
      </ChatAsideFooterStyled>
    </ChatAsideContainerStyled>
  );
};

import { Centrifuge } from 'centrifuge';
import { WS_URL } from '../../../packages/keycloak-client/constants';
import {
  getAccessTokenToConnectWS,
  getAccessTokenToConnectWSReferee,
} from './getAccessTokenToConnectWS';

let centrifugeInstance: Centrifuge | null = null;

export const centrifugeInstanceCreate = (
  isReferee?: boolean,
  token?: string | null
) => {
  if (!isReferee) {
    centrifugeInstance = new Centrifuge(WS_URL, {
      name: 'edenex',
      getToken: getAccessTokenToConnectWS,
      minReconnectDelay: 5000,
      maxReconnectDelay: 20000,
    });
  }
  if (isReferee) {
    centrifugeInstance = new Centrifuge(WS_URL, {
      name: 'edenex',
      getToken: getAccessTokenToConnectWSReferee,
      minReconnectDelay: 5000,
      maxReconnectDelay: 20000,
    });
  }
  return centrifugeInstance;
};

export const centrifugeInstanceConnect = () => {
  centrifugeInstance?.connect();
  return centrifugeInstance?.state;
};

export const centrifugeInstanceDisconnect = () => {
  centrifugeInstance?.disconnect();
  return centrifugeInstance?.state;
};

import styled from 'styled-components';
import { Statistic } from 'antd';

export const OfferChatContainerStyled = styled.div`
  flex: 1;
  min-width: 380px;
  justify-self: flex-end;
  position: relative;
  background-color: #14141f;
  color: white;
  font-size: 14px;
  line-height: 20px;
  height: 100%;
  max-height: calc(100vh - 75px);
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #413d50;
`;

export const OfferChatHeaderStyled = styled.div`
  border-top: 1px solid #413d50;
  border-bottom: 1px solid #413d50;
  height: 80px;
  padding: 24px;
  position: relative;
`;

export const OfferChatHeaderSubInfoStyled = styled.div`
  color: white;
  opacity: 0.5;
  font-size: 12px;
  position: absolute;
  bottom: 0;
`;

export const OfferChatBodyWrapperStyled = styled.div<{ $isEmpty?: boolean }>`
  padding-bottom: 18px;
  max-height: calc(100vh - 270px);
  height: 100%;
`;

export const OfferMessagesWrapper = styled.div`
  margin: auto 16px 0 0;
`;

export const OfferChatFooterStyled = styled.div`
  position: sticky;
  bottom: 0;
  width: 100%;
  min-height: fit-content;
  padding: 0 12px 36px 8px;
  background: #14141f;
  border-bottom: 1px solid #413d50;
`;

const { Countdown } = Statistic;

export const TimerContainerStyled = styled.div`
  display: flex;
  align-items: baseline;
  gap: 3px;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  color: #767687;
`;

export const ChatHeaderInfoTempTimerStyled = styled(Countdown)`
  .ant-statistic-content-value {
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
    color: #767687;
    transition: ease-in-out 0.5s;
    cursor: pointer;
  }
`;

import { useTranslation } from 'react-i18next';
import React from 'react';
import { EdenexIcon } from '../../../../assets/img/icons/icons';
import {
  IconContainerStyled,
  RefereeChatHeaderStyled,
} from './RefereeChatStyles';

type ChatHeaderProps = {
  dealID: string;
};

export const RefereeChatHeader = ({ dealID }: ChatHeaderProps) => {
  const { t } = useTranslation();

  return (
    <RefereeChatHeaderStyled>
      <IconContainerStyled>
        <EdenexIcon />
      </IconContainerStyled>
      <div>
        {t('Сделка')} {dealID}
      </div>
    </RefereeChatHeaderStyled>
  );
};

import React from 'react';
import styled from 'styled-components';

type TPropsErrorBoundary = {
  children: React.ReactNode;
  onCallBack?: () => void;
};

export class ErrorBoundary extends React.Component<
  TPropsErrorBoundary,
  { hasError: boolean }
> {
  constructor(props: TPropsErrorBoundary) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    if (!!this.props.onCallBack) {
      this.props?.onCallBack();
    }
  }

  render() {
    if (this.state.hasError) {
      return <ErrorTextStyled>Something went wrong.</ErrorTextStyled>;
    }

    return this.props.children;
  }
}

const ErrorTextStyled = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`;
